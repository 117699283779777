<template>
  <step-list :step="3" />

  <div class="Step-title Step-title--bgOrange">
    Félicitations !
  </div>
  <div class="Step-content">
    <p class="Step-txt Step-txt--big">
      Vous bénéficiez désormais de votre remise additionnelle
    </p>
    <div class="Step-backToFirst">
      <a @click="() => $emit('back')" href="#" class="Step-backToFirstLink"
        >Enregistrer un autre produit</a
      >
    </div>
  </div>
</template>

<script>
import StepList from "./StepList.vue";
export default {
  components: { StepList },
  name: "Validation",
  props: {},
};
</script>
