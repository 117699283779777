<template>
  <div class="Step-content">
    <img
      src="../assets/img/pub2.svg"
      alt="Bénéficiez d'une remise additionnelle (à valoir sur tradi auto ou volet roulant pour integration)"
      class="Step--01-img"
    />
    <div class="Step--01-txtContainer">
      <p class="Step--01-txt">
        * En équipant un coffre polystyrène vide d’un volet roulant tradi auto
        ou d’un volet pour Intégration équipé d’un tablier aluminium et motorisé
        Zuni R ou Zigbee de marque Eveno Fermetures.
      </p>
      <p class="Step--01-txt">
        Sont exclus de cette opération tous les coffres polystyrènes vides
        bénéficiant d’un référencement spécifique (hors référencement stock).
      </p>
    </div>
    <div class="ButtonDesktop">
      <a @click.prevent="$attrs.onNext" href="#" class="Button"
        >Je profite de l'offre</a
      >
    </div>
  </div>

  <div class="MobileFixedFooter">
    <a @click.prevent="$attrs.onNext" href="#" class="Button"
      >Je profite de l'offre</a
    >
  </div>
</template>

<script>
export default {
  name: "Introduction",
  props: {},
};
</script>
