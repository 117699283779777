<template>
  <root :step="step">
    <template v-if="step <= 1">
      <Introduction @next="goToCouponCode" />
    </template>
    <template v-if="step === 2">
      <CouponCode v-model:code="couponCode" @next="goToOrderCode" />
    </template>
    <template v-if="step === 3">
      <OrderCode
        v-model:code="orderCode"
        :couponCode="couponCode"
        @previous="goToCouponCode"
        @next="goToValidation"
    /></template>
    <template v-if="step === 4"> <Validation @back="restart"/></template>
  </root>
</template>

<script>
import Introduction from "./components/Introduction.vue";
import CouponCode from "./components/CouponCode.vue";
import OrderCode from "./components/OrderCode.vue";
import Validation from "./components/Validation.vue";
import Root from "./components/Root.vue";

export default {
  name: "App",
  components: {
    Introduction,
    CouponCode,
    OrderCode,
    Validation,
    Root,
  },
  data() {
    return {
      step: 1,
      couponCode: "",
      orderCode: "",
    };
  },
  methods: {
    goToCouponCode() {
      this.step = 2;
    },
    goToOrderCode() {
      this.step = 3;
    },
    goToValidation() {
      this.step = 4;
    },
    restart() {
      this.step = 1;
      this.couponCode = "";
      this.orderCode = "";
    },
  },
};
</script>

<style lang="scss">
@import "./scss/normalize.css";

@import "./scss/global/Variables";
@import "./scss/global/Mixins";
@import "./scss/global/Global";

@import "./scss/components/Container";
@import "./scss/components/Row";
@import "./scss/components/Col";
@import "./scss/components/Root";
@import "./scss/components/LeftPanel";
@import "./scss/components/RightPanel";
@import "./scss/components/Logo";
@import "./scss/components/Button";
@import "./scss/components/ButtonDesktop";
@import "./scss/components/MobileFixedFooter";
@import "./scss/components/PanelContainer";
@import "./scss/components/ExternalLink";
@import "./scss/components/Step";
@import "./scss/components/StepsList";
@import "./scss/components/Form";
</style>
