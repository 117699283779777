<template>
  <Head>
    <title>Promotion exceptionnelle - Eveno Fermetures</title>
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Anton&amp;family=Roboto:wght@400;700&amp;display=swap"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </Head>
  <div class="Root">
    <external-link />

    <div class="Container">
      <div class="Row">
        <div class="Col Col-12">
          <logo />
        </div>
      </div>
      <div class="PanelContainer">
        <div class="Row Row--noGutters">
          <div class="Col Col-6 Col-sm-12">
            <div class="LeftPanel" :class="`LeftPanel--step0${step}`">
              <div class="LeftPanel-imgContainer">
                <img
                  src="../assets/img/pub1.svg"
                  alt="Du 01 avril 2021 au 28 février 2022 des coffres 100% gagnants"
                  class="LeftPanel-img"
                />
              </div>
            </div>
          </div>
          <div class="Col Col-6 Col-sm-12">
            <div class="RightPanel" :class="`RightPanel--step0${step}`">
              <div class="RightPanel-container">
                <div class="Step" :class="`Step--0${step}`">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from "@egoist/vue-head";

import ExternalLink from "./ExternalLink.vue";
import Logo from "./Logo.vue";
export default {
  components: { Head, ExternalLink, Logo },
  name: "Root",
  props: {
    step: { type: Number, required: true, default: 1 },
  },
};
</script>
