<template>
  <step-list :step="2" @update:step="(val) => val === 1 && $emit('previous')" />
  <div class="Step-title">
    ENTREZ LE NUMÉRO DE COMMANDE
  </div>
  <div class="Step-content">
    <span class="Step-numCoffre">Numéro de coffre : {{ couponCode }}</span>
    <p class="Step-txt">
      Ce coffre est valide.<br />
      Entrez maintenant le numéro de votre commande K afin d’assurer une
      tracabilité
    </p>
    <form id="step03" action="" class="Form" @submit.prevent="submitForm">
      <div class="Form-row">
        <input
          type="text"
          :value="code"
          required
          @input="(event) => $emit('update:code', event.target.value)"
          class="Form-input"
          placeholder="Ex : K172531/3907"
        />
        <span v-if="error" class="Form-errorMsg">{{ error }}</span>
      </div>
      <div class="ButtonDesktop">
        <button
          type="submit"
          class="Button Form-submit"
          :class="{ 'Button--disabled': !canSubmit }"
        >
          <span v-if="loading">Enregistrement en cours&hellip;</span
          ><span v-else>Valider</span>
        </button>
      </div>

      <div class="MobileFixedFooter">
        <button
          type="submit"
          class="Button Form-submit"
          :class="{ 'Button--disabled': !canSubmit }"
          form="step03"
        >
          <span v-if="loading">Enregistrement en cours&hellip;</span
          ><span v-else>Valider</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import StepList from "./StepList.vue";
import { api, axios } from "../api.js";

export default {
  components: { StepList },
  name: "OrderCode",
  props: {
    code: String,
    couponCode: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      called: false,
      error: null,
      success: false,
    };
  },
  computed: {
    canSubmit() {
      return !this.loading && this.code && this.couponCode;
    },
  },
  methods: {
    submitForm() {
      if (!this.canSubmit) {
        this.error = "Vous devez renseigner un numéro de commande valide";
        return;
      }
      this.registerCoupon(this.couponCode, this.code);
    },
    registerCoupon(couponCode, order) {
      this.error = null;
      this.success = false;
      if (!(couponCode || "").match(/^\d{4}$/)) return;
      this.loading = true;
      this.called = true;
      const params = new URLSearchParams();
      params.append("order", order);
      axios
        .post(
          api("/registerCouponCode?coupon=" + encodeURIComponent(couponCode)),
          params
        )
        .then(({ data } = {}) => {
          switch (data) {
            case "OK":
              this.success = true;
              this.$emit("next");
              break;
            default:
            case "ERROR":
              this.error = "Une erreur inconnue est apparue";
              break;
          }
        })
        .catch((error) => {
          console.error(error);
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
