<template>
  <div class="Logo">
      <img src="../assets/img/logo-eveno.svg" alt="Logo Eveno Fermetures" class="Logo-img">
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
  }
}
</script>
