<template>
  <div class="Step-listContainer">
    <div class="StepsList">
      <div
        class="StepsList-item"
        :class="{ 'StepsList-item--active': step === 1 }"
        @click="() => $emit('update:step', 1)"
      >
        1
      </div>
      <div
        class="StepsList-item"
        :class="{ 'StepsList-item--active': step === 2 }"
        @click="() => $emit('update:step', 2)"
      >
        2
      </div>
      <div
        class="StepsList-item StepsList-item--check"
        :class="{ 'StepsList-item--active': step === 3 }"
        @click="() => $emit('update:step', 3)"
      >
        <img src="../assets/img/check.svg" alt="Terminé" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCode",
  props: {
    step: Number,
  },
};
</script>
