<template>
  <div class="ExternalLink">
      <a href="https://www.eveno-fermetures.com/" target="_blank" class="ExternalLink-container">
          <span class="ExternalLink-wrapper">
              <span class="ExternalLink-name">eveno-fermetures.com</span>
              <span class="ExternalLink-boxArrow"><img src="../assets/img/arrow-right.svg" class="ExternalLink-arrow"></span>
          </span>
      </a>
  </div>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
  }
}
</script>
