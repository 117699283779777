<template>
  <step-list :step="1" />
  <div class="Step-title">
    ENTREZ LE NUMÉRO DE COFFRE
  </div>
  <div class="Step-content">
    <p class="Step-txt">
      Afin de bénéficier de cette offre vous devez vérifier la validité du
      numéro d’identification du coffre qui vous a été remis (4 chiffres)
    </p>
    <form id="step02" action="" class="Form" @submit.prevent="submitForm">
      <div class="Form-row">
        <input
          type="text"
          class="Form-input"
          pattern="\d*"
          maxlength="4"
          required
          :value="code"
          @input="(event) => checkCode(event.target.value)"
          placeholder="Ex : 0055"
        />
        <span v-if="error" class="Form-errorMsg">{{ error }}</span>
      </div>
      <div class="ButtonDesktop">
        <button
          type="submit"
          class="Button Form-submit"
          :class="{ 'Button--disabled': loading || !success }"
        >
          <span v-if="loading">Vérification&hellip;</span
          ><span v-else>Valider</span>
        </button>
      </div>

      <div class="MobileFixedFooter">
        <button
          type="submit"
          class="Button Form-submit"
          :class="{ 'Button--disabled': loading || !success }"
          form="step02"
        >
          <span v-if="loading">Vérification&hellip;</span
          ><span v-else>Valider</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import StepList from "./StepList.vue";
import { api, axios } from "../api.js";

export default {
  components: { StepList },
  name: "CouponCode",
  props: {
    code: String,
  },
  data() {
    return {
      called: false,
      loading: false,
      error: null,
      success: false,
    };
  },
  methods: {
    submitForm() {
      if (!this.success) {
        this.error = "Vous devez avoir un code valide pour continuer";
        return;
      }
      this.$emit("next");
    },
    checkCode(couponCode) {
      this.$emit("update:code", couponCode);
      this.error = null;
      this.success = false;
      if (!(couponCode || "").match(/^\d{4}$/)) return;
      this.loading = true;
      this.called = true;
      axios
        .get(api("/checkCouponCode?coupon=" + encodeURIComponent(couponCode)))
        .then(({ data } = {}) => {
          switch (data) {
            case "NO":
              this.error = "Le code est invalide";
              break;
            case "USED":
              this.error = "Le code est déjà utilisé";
              break;
            case "VALID":
              this.success = true;
              break;
            default:
            case "ERROR":
              this.error = "Une erreur inconnue est apparue";
              break;
          }
        })
        .catch((error) => {
          console.error(error);
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
